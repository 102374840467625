#titulo{font-size: 16px;}
#desc{padding-left: 10px;}
#endereco {font-size: 16px;}
#btnPesquisar{margin-left: 5px;}
#descricao{padding-bottom: 10px;}
#chave{padding-left: 10px;}

.Header{
    top: 0;
    background-color: white;
}

.App-header {
    height: 100%;
    min-height: 150px;
    background-color: #fff;
    color: #292929;}

img {padding: 5px;}

.outsider {
    background-color: #f5f5f5;
    width: 100%;
    border-radius: 5px;
}

.header-text {
    position: fixed;
    padding: 43px;
    font-size: 28px;
    text-align: center;
    top: 0;}

body{position: relative;
     background-color: #fdfffa;
     font-family: sans-serif;}

a{color: #000}

.footer{
    padding-top: 10px;
    text-align: center;
    padding-bottom: 10px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;}

.footer > span {color: #878787;}

.SearchB {
    font-size: 15px;
    padding: 10px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .grid-item {
    background-color: #f5f5f5;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid #ccc;
    padding: 13px;
    font-size: 20px;
    text-align: left;
  }